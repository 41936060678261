import React from "react"
import {
  Section,
  Container,
  Column,
  Columns,
  Button,
  Content,
} from "bloomer"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import cristaisData from "../../data/oraculo/cristais/cristais.json"

export default class Cristais extends React.Component {
  state = {
    selected: []
  }

    // handleClick = event => {
    //     // let random = cristaisData.sort(() => .5 - Math.random()).slice(0,3)
    //     // this.setState({selected:random})
    //     console.log(event)
    // }

  render() {
    var parent = this
    const {selected} = this.state;

    let items =""

    if (selected.length === 3){
         items = selected.map(function(cristal, i){
            if(cristal['id']){
                const image = require("../../data/oraculo/cristais/images/"+cristal["id"]+".png")
                return (
                <Content key={i} className="media">
                    <div className="media-left">
                        <img src={image} alt={cristal['name']} />
                    </div>
                    <div className="media-content">
                        <h2>Nome: {cristal["name"]}</h2>
                        <p> {cristal["resume"]}</p>
                        <p> {cristal["text"]}</p>
                    </div>
                </Content>
                )
            }else{
                return ""
            }
            
        });
        items.push(<Button onClick={() => { parent.setState({selected:[]})}}>Voltar</Button>)
        //
        console.log(items)
    }else{
         items = cristaisData.map(function(cristal, i){
            if(cristal['id']){
                const active = selected.includes(cristal)
                const image = require("../../data/oraculo/cristais/images/"+cristal["id"]+".png")
                return (
                       <Button 
                            key={i}
                            onClick={() => {
                                if(!active){
                                    const selectedtmp = selected;   
                                    selectedtmp.push(cristal);   
                                    parent.setState({selected:selectedtmp})
                                }                            
                            }}
                            className={active?'is-active column is-2-tablet is-4-mobile':'column is-2-tablet is-4-mobile'}
                        >
                            <img src={image} alt={cristal['name']}/>
                        </Button>
                
                )
            }else{
                return ""
            }
            
        });
        items = <Columns className="cristais" isMobile isMultiline>{items}</Columns>

    }
    
 
    return (
      <Layout>
        <SEO title="Oráculo dos Cristais - Estrelaguia Guia" />
          <Container>
            <Columns>
              <Column>
              <Content>
                <h1>Oráculo dos Cristais</h1>
                <p>
                Os cristais funcionam como condutores e amplificadores de energia e, por isso, funcionam como um elo de ligação entre as energias de nossos corpos sutis, atuando em todos os niveis: físico, mental, emocional e espiritual. Por isso, podem auxiliar nos processos de cura, meditações, energização e harmonização de ambientes e pessoas.
                </p>
                <p>
                O Oráculo de Cristais é um método de consulta intuitiva, que nos liga diretamente ao nosso subconsciente.
                </p>
                <p>
                O cristal escolhido trará uma mensagem a respeito das energias ou desarmonias que possam estar influenciando a pessoa ou o assunto em questão no momento da consulta. Ao mesmo tempo, este cristal será um aliado para a harmonização e a transmissão destas mesmas energias. Ou seja, a pedra escolhida tanto indica o desequilibrio como apresenta o caminho da cura. Ao final de cada mensagem, serão dadas algumas dicas de utilização prática destes cristais, para que seus edeitos possam ser absorvidos da melhor forma possível.
                </p>
                <p>
                Procure silenciar um pouco a mente e concentre-se em seu momento de vida, ou em alguma questão específica. Olhe para as imagens das pedras e, sem pensar muito, escolha aquela que mais te atrai a atenção.                
                </p>
                
                </Content>
                <Content>
                {items}
                </Content>
              </Column>
            </Columns>
          </Container>
      </Layout>
    )
  }
}


